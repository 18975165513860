import React from 'react';
import {LoadingIndicator, LOADING_MESSAGE} from '@fupa/fupa-uikit';
import {StreamPlaceholder, LoadingWrapper} from 'app/styles/stream';
import PropTypes from 'prop-types';
import {initAdPlacement} from 'app/components/ads/initAdPlacement';
import NewsPublishedActivity from 'app/components/stream/activities/newsPublishedActivity';
import PlaylistPublishedActivity from 'app/components/stream/activities/PlaylistPublishedActivity';
import GalleryPublishedActivity from 'app/components/stream/activities/GalleryPublishedActivity';
import ProfileTransferActivity from 'app/components/stream/activities/ProfileTransferActivity';
import CoachChangedActivity from 'app/components/stream/activities/CoachChangedActivity';
import MatchDayResultActivity from 'app/components/stream/activities/MatchDayResultActivity';
import MatchKickoffChangedActivity from 'app/components/stream/activities/MatchKickoffChangedActivity';
import MatchResultActivity from 'app/components/stream/activities/MatchResultActivity';
import PlayerBannedActivity from 'app/components/stream/activities/PlayerBannedActivity';
import LeagueFormStandingActivity from 'app/components/stream/activities/LeagueFormStandingActivity';
import LeagueTopElevenActivity from 'app/components/stream/activities/LeagueTopElevenActivity';
import PlayerInjuredActivity from 'app/components/stream/activities/PlayerInjuredActivity';
import CompetitionScorersActivity from 'app/components/stream/activities/CompetitionScorersActivity';
import MatchEventActivity from 'app/components/stream/activities/MatchEventActivity';
import styled from 'styled-components';
import {StreamCard} from 'app/components/stream/streamCard';
import {AdSlot} from 'app/components/ads/AdSlot';
import {sendEventToGA4} from 'app/helpers/gtagHelpers';
import {generateUniqueTemplateKey} from 'app/helpers/gtm';
import {useLocation} from 'react-router';

const SCContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  ${props =>
    props.theme.desktopLayout`
      justify-content: space-between;
    `}
`;

const typeToComponent = {
  NewsPublished: (activity, heroLayout) => <NewsPublishedActivity activity={activity} heroLayout={heroLayout} />,
  PlaylistPublished: (activity, isFirstElement) => (
    <PlaylistPublishedActivity activity={activity} isFirstElement={isFirstElement} />
  ),
  GalleryPublished: (activity, isFirstElement) => (
    <GalleryPublishedActivity activity={activity} isFirstElement={isFirstElement} />
  ),
  ProfileTransfer: activity => <ProfileTransferActivity activity={activity} />,
  CoachChanged: activity => <CoachChangedActivity activity={activity} />,
  MatchDayResults: activity => <MatchDayResultActivity activity={activity} />,
  MatchKickoffChanged: activity => <MatchKickoffChangedActivity activity={activity} />,
  MatchResult: activity => <MatchResultActivity activity={activity} />,
  PlayerBanned: activity => <PlayerBannedActivity activity={activity} />,
  CompetitionSeasonFormStandings: activity => <LeagueFormStandingActivity activity={activity} />,
  LeagueTopEleven: activity => <LeagueTopElevenActivity activity={activity} />,
  PlayerInjured: activity => <PlayerInjuredActivity activity={activity} />,
  CompetitionScorers: activity => <CompetitionScorersActivity activity={activity} />,
  MatchEvent: activity => <MatchEventActivity activity={activity} />,
  default: () => null,
};

const StreamContainer = ({
  isFetching,
  nextPage,
  error,
  stream,
  adSlots,
  noDesktopAds,
  desktopNative_1,
  adContainerOffset = 0,
}) => {
  let elements;

  const {pathname} = useLocation();
  const {template} = generateUniqueTemplateKey(pathname);

  if (stream.length === 0) {
    elements = null;
  } else {
    // Logic for new Stream Service 🔥
    elements = stream.map((activity, index) => {
      const getStreamElement = typeToComponent[activity.type]
        ? typeToComponent[activity.type]
        : typeToComponent['default'];
      const isFirstElement = index === 0;

      const component = getStreamElement(activity, isFirstElement);
      if (!component) {
        return null;
      }
      return (
        <StreamCard
          onClick={() =>
            sendEventToGA4('select_content', {
              content_type: activity.type,
              item_id: activity.activity.slug,
              index,
              content_group: template,
            })
          }
          entity={activity.type}
          key={activity.id}
          heroLayout={isFirstElement && activity.type === 'NewsPublished'}>
          {component}
        </StreamCard>
      );
    });
  }

  // ### START PLACE ADS ###
  // Need to place all adSlots for both layouts based on media queries to avoid missmatch after hydration
  //Ads for mobile layout
  initAdPlacement(elements, adContainerOffset, 3, adSlots, 'mobile');

  if (!noDesktopAds) {
    // Ads for desktop layout
    if (elements && elements.length > 0) {
      const firstElementIsNews = stream[0]?.type === 'NewsPublished';
      const moveStart = firstElementIsNews ? 1 : 0;

      // if adContainerOffset is 0, we need to manipulate "moveStart" for the last adSlot
      // because there are three empty mobile ad slots placed before
      // with the default adContainerOffset of -1 there are only two empty mobile ad slots placed before 'Superbanner_3'

      const moveStartSuperbanner_2 = adContainerOffset === 0 ? moveStart + 1 : moveStart;
      desktopNative_1 && elements.length > 2 + moveStart
        ? elements.splice(3 + moveStart, 0, <AdSlot adLayout='desktop' key='Native_1' adUnit='Native_1' />)
        : null;
      elements.length > 5 + moveStart
        ? elements.splice(7 + moveStart, 0, <AdSlot adLayout='desktop' key='Superbanner_2' adUnit='Superbanner_2' />)
        : null;
      elements.length > 10 + moveStart
        ? elements.splice(
            13 + moveStartSuperbanner_2,
            0,
            <AdSlot adLayout='desktop' key='Superbanner_3' adUnit='Superbanner_3' />
          )
        : null;
    }
  }
  // ### END PLACE ADS ###

  const content =
    stream.length === 0 && !isFetching && !error ? (
      <StreamPlaceholder>Keine Meldungen vorhanden.</StreamPlaceholder>
    ) : (
      elements
    );
  const showLoading = isFetching ? (
    <LoadingWrapper>
      <LoadingIndicator message={LOADING_MESSAGE.loadingStream} bgColor />
    </LoadingWrapper>
  ) : null;
  const showEnd =
    !nextPage && stream.length > 0 && !error ? (
      <StreamPlaceholder>Es sind keine weiteren Meldungen vorhanden.</StreamPlaceholder>
    ) : null;

  const showError = error ? <StreamPlaceholder>Beim Laden ist ein Fehler aufgetreten.</StreamPlaceholder> : null;

  return (
    <SCContent>
      {content}
      {showLoading}
      {showEnd}
      {showError}
    </SCContent>
  );
};

StreamContainer.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  nextPage: PropTypes.string,
  error: PropTypes.bool,
  stream: PropTypes.array.isRequired,
};

export default StreamContainer;
