import React, {useEffect, useReducer} from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import MarketplaceContainer from 'app/components/marketplace/MarketplaceContainer';
import {marketplaceFilters} from 'app/components/stream/streamFilter.const';
import {Helmet} from 'react-helmet-async';
import {overwriteMarketplace, fetchDistrictMarketplace} from 'app/routes/district/DistrictPageActions';
import {useInfiniteScrollComponentState} from 'app/hooks/useInfiniteScroll';
import {useParams} from 'react-router';
import {StreamFilterFab} from 'app/components/floatingAction/StreamFilterFab';
import {AddMarketplacePostFab} from 'app/components/floatingAction/AddMarketplacePostFab';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {DistrictMarketplacePageReducer} from 'app/routes/district/DistrictPageReducer';

const streamRegex = /\/region\/[a-z0-9-]+\/marketplace\//;

const DistrictMarketplacePage = ({name, dispatchRedux}) => {
  const {restoredData} = useRestoreData('DistrictMarketplacePage');
  const {districtSlug, categories} = useParams();
  const initialData = {
    isFetching: false,
    items: [],
    nextUrl: '',
    categoryFilter: categories,
    error: null,
  };
  const initialState = restoredData ?? initialData;
  const [state, dispatch] = useReducer(DistrictMarketplacePageReducer, initialState);

  const loadData = (reloading, reset) => {
    const componentState = reset ? initialData : state;
    return fetchDistrictMarketplace(districtSlug, categories, reloading, dispatchRedux)(dispatch, componentState);
  };
  const {items, isFetching, nextPage, error, categoryFilter} = state;
  useInfiniteScrollComponentState(loadData, items.length);
  useFetchData(state, loadData, 'DistrictMarketplacePage', true);

  useEffect(() => {
    // Always load new data when switching between filters
    if (categoryFilter !== categories) {
      dispatch(overwriteMarketplace(initialData));
      loadData(false, true);
    }
  }, [categories]);

  const filter = marketplaceFilters.find(filter => filter.param === categories);
  const title = filter ? `${filter.value} - Vereinsbörse - ` : 'Vereinsbörse - ';
  const meta = [
    {
      name: 'description',
      content: `Testspielgegner gesucht? Suche und finde Spieler, Trainer, einen neuen Fußball-Verein oder eine Mannschaft für ein Freundschaftsspiel in der ${name} Vereinsbörse.`,
    },
    {
      name: 'robots',
      content: 'index, nofollow',
    },
  ];

  return (
    <ContentAdDesktop
      bgColor='none'
      top='7.125rem'
      fabBtn={
        <>
          <AddMarketplacePostFab />
          <StreamFilterFab filter={marketplaceFilters} regex={streamRegex} />
        </>
      }>
      <Helmet title={title} meta={meta} />
      <MarketplaceContainer
        elements={items}
        error={error}
        isFetching={isFetching}
        nextPage={nextPage}
        district={districtSlug}
      />
    </ContentAdDesktop>
  );
};

export default DistrictMarketplacePage;
