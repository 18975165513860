import {useEffect, useMemo, useState} from 'react';

export const useSwipeableViewsActiveStep = (views: any[]) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const maxSteps = useMemo(() => views.length - 1, [views.length]);

  const handleNext = () => {
    if (activeStep < maxSteps) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const handleIndexChange = (index: number) => {
    setActiveStep(index);
  };

  useEffect(() => {
    if (activeStep > maxSteps) {
      setActiveStep(maxSteps);
    }
  }, [maxSteps]);

  return {activeStep, maxSteps, handleNext, handleBack, handleIndexChange};
};
