import React, {useEffect, useReducer} from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import StreamContainer from 'app/components/stream/streamContainer';
import {Helmet} from 'react-helmet-async';
import {fetchDistrictNews, overwriteNews} from 'app/routes/district/DistrictPageActions';
import {useInfiniteScrollComponentState} from 'app/hooks/useInfiniteScroll';
import {string} from 'prop-types';
import LeagueNewsPage from 'app/routes/league/LeagueNewsPage';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {useParams} from 'react-router';
import {DistrictNewsPageReducer} from 'app/routes/district/DistrictPageReducer';
import {useLogOutdatedContent} from 'app/hooks/useLogOutdatedContent';
import StickyMobileBannerAdSlot from 'app/components/ads/StickyMobileBannerAdSlot';
import {updateLastDistricts} from 'app/helpers/lastItemsHelpers';

const DistrictNewsPage = ({name, slug, dispatchRedux}) => {
  const {restoredData} = useRestoreData('DistrictNewsPage');
  const {districtSlug, categories} = useParams();
  const initialData = {
    isFetching: false,
    items: [],
    nextUrl: '',
    categoryFilter: categories,
    error: null,
  };
  const initialState = restoredData ?? initialData;
  const [state, dispatch] = useReducer(DistrictNewsPageReducer, initialState);

  const loadData = (reloading, reset) => {
    const componentState = reset ? initialData : state;
    return fetchDistrictNews(districtSlug, categories, reloading, dispatchRedux)(dispatch, componentState);
  };

  const {items, isFetching, nextUrl, error, categoryFilter} = state;
  useInfiniteScrollComponentState(loadData, items.length);
  useFetchData(state, loadData, 'DistrictNewsPage', true);

  useLogOutdatedContent();

  useEffect(() => {
    if (slug && name && slug === districtSlug) {
      const districtData = {
        slug,
        name,
      };
      updateLastDistricts(districtData);
    }
  }, [slug, name, districtSlug]);

  useEffect(() => {
    // Always load new data when switching between filters
    if (categoryFilter !== categories) {
      dispatch(overwriteNews(initialData));
      loadData(false, true);
    }
  }, [categories]);

  const adSlots = ['Native_1', 'Rectangle_1', 'Content_2', 'Rectangle_2'];
  const meta = [
    {
      name: 'description',
      content: `Fußballkreis ${name} - Alle wichtigen Informationen zum Amateurfußball im Fußballkreis ${name}.`,
    },
    {
      name: 'robots',
      content: 'index',
    },
  ];

  return (
    <>
      <ContentAdDesktop bgColor='none' top='7.125rem'>
        <Helmet meta={meta} />
        <StreamContainer
          isFetching={isFetching}
          error={Boolean(error)}
          nextPage={nextUrl}
          stream={items}
          adSlots={adSlots}
          desktopNative_1
        />
      </ContentAdDesktop>
      <StickyMobileBannerAdSlot />
    </>
  );
};

LeagueNewsPage.propTypes = {
  name: string.isRequired,
};

export default DistrictNewsPage;
