import React from 'react';
import MarketplaceElement from 'app/components/marketplace/MarketplaceElement';
import MarketplaceHeader from 'app/components/marketplace/MarketplaceHeader';
import {LinkOnComponent} from 'app/components/links/link';
import {LoadingWrapper, StreamPlaceholder} from 'app/styles/stream';
import {LoadingIndicator, LOADING_MESSAGE} from '@fupa/fupa-uikit';
import {initAdPlacement} from 'app/components/ads/initAdPlacement';
import {StreamCard} from 'app/components/stream/streamCard';
import styled from 'styled-components';
import {AdSlot} from 'app/components/ads/AdSlot';
import District from 'app/models/District';

const MarketplaceContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  ${props =>
    props.theme.desktopLayout`
      justify-content: space-between;
    `}
`;

const MarketplaceContainer = ({elements, isFetching, nextPage, district, error}) => {
  const items =
    elements.length > 0
      ? elements.map(element => {
          return (
            <StreamCard entity='Marketplace' key={element.id}>
              <LinkOnComponent to={new District(district).getSubUrl(`marketplace/detail/${element.id}`)}>
                <MarketplaceHeader element={element} />
                <MarketplaceElement entity={element} />
              </LinkOnComponent>
            </StreamCard>
          );
        })
      : null;

  // ### START PLACE ADS ###
  // Need to place all adSlots for both layouts based on media queries to avoid missmatch after hydration
  //Ads for mobile layout
  initAdPlacement(items, -2, 3, ['Content_1', 'Rectangle_1', 'Content_2', 'Rectangle_2'], 'mobile');

  // Ads for desktop layout
  if (items?.length > 0) {
    items.length > 5
      ? items.splice(6, 0, <AdSlot adLayout='desktop' key='Superbanner_2' adUnit='Superbanner_2' />)
      : null;
    items.length > 12
      ? items.splice(13, 0, <AdSlot adLayout='desktop' key='Superbanner_3' adUnit='Superbanner_3' />)
      : null;
  }
  // ### END PLACE ADS ###

  const content =
    elements.length === 0 && !isFetching ? <StreamPlaceholder>Keine Meldungen vorhanden.</StreamPlaceholder> : items;
  const showLoading = isFetching ? (
    <LoadingWrapper>
      <LoadingIndicator message={LOADING_MESSAGE.loadingStream} bgColor />
    </LoadingWrapper>
  ) : null;
  const showEnd =
    !nextPage && elements.length > 0 && !error ? (
      <StreamPlaceholder>Es sind keine weiteren Meldungen vorhanden.</StreamPlaceholder>
    ) : null;

  const showError = error ? <StreamPlaceholder>Beim Laden ist ein Fehler aufgetreten.</StreamPlaceholder> : null;

  return (
    <MarketplaceContent>
      {content}
      {showLoading}
      {showEnd}
      {showError}
    </MarketplaceContent>
  );
};

export default MarketplaceContainer;
